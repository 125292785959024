

/*   */

body {
  user-select: none;
}

img {
  max-height: 40%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}




.bottomNav {
  height: 20px;
	color: #f2f2f2;
	position:fixed;
	z-index:70;
	bottom:0;
	width:100%;
	text-align:right;
  right: 0px;
	font-size:0.9em;
	padding:0px 80px 60px 20px
  /* background-color: white; */
}

.bottomNav > div {
  float: right;
}

#infoMenu {
	height: 20px;
	color: #f2f2f2;
	position:fixed;
	z-index:70;
	bottom:0;
	width:200px;
	text-align:left;
	font-size:0.9em;
	padding:9px 0 48px 0;
  /* background-color: white; */
}



#logotTop {
	height: 70px;
	color: #f2f2f2;
	position:fixed;
	z-index:70;
	top:0;
	width:100%;
	text-align:left;
	font-size:0.9em;
	padding:8px 0 8px 0;
  /* background-color: white; */
}
.navbar-brand {
  display: block;
  /* width: 243px;
  height: 27px; */
  padding: 0;
    padding-right: 0px;
  border-right: #0179aa 2px solid;
  padding-right: 25px;
}
#logo-right-parent > div {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 400px;
  color: #0179aa;
  padding-top: 37px;
  padding-left: 17px;
}
.navbar-brand--lockup {
  float: left;
  color: #006BA6;
  display: inline-block;
  font-size: 20px;
  line-height: 1.8;
  margin-left: 8px;
}

.bullet {
  display: list-item;
  margin-left : 1.1em;
  text-align: left;
}

.bottom {
  position: fixed;
  z-index: 70;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 90%;
}

.bottom-downarrow {
  width: 50px;
}

#studyName {
  color: #006BA6;
  display: inline;
  font-size: 24px;
  line-height: 1.8;
  margin-left: 8px;
  position: relative;
  top: -46px;
  left: 207px;
  user-select: none;
}

.fp-slides {
  width: 100%;
}

.section-inner {
  padding: 0 3em;
  height: 90%;
}

.section-inner > div {
  width: 100%;
}

.vertical-distribute {
  height: 100%;
  /* height: initial; */

  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column !important;
  justify-content: space-evenly;
  box-sizing: border-box;
  overflow: hidden;
}

.vertical-distribute::-webkit-scrollbar { 
  display: none; 
  }

.section-inner > div:first-of-type {
  /* height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column !important;
  justify-content: space-evenly; */
  justify-content: flex-start;
}

.section-inner h1 {
  /* font-size: 2em; */
  padding: 0px;
  margin: 0px 0px 10px;
  opacity: 95%;
}

.text-headline {
  font-weight: 600;
}

.text-pageTitle {
  font-weight: 600;
  padding-bottom: '1em';
}

.fp-section.section {
  /* color: rgb(243, 243, 243);
  color: #4d4d4d; */
  color: #242424;
  display: inherit;
}

.section.dark-text {
  color: rgb(24 24 24 / 80%);
}

.section #WelcomeSection {
  color: #282828;
  display: flex;
}

.slide-buttons {
  padding-top: 50px;
}

.slide-control-button-row {
  width: 100%;
}

.slide-control-button-row-inner {
  transition: opacity .5s linear;
}

.MuiButtonBase-root.card-button-back-front  {
  float: left;
}

.MuiButtonBase-root.card-button-undo {
  float: left;
}

/* .fp-tableCell {
  display: flex;
  align-items: center;
  justify-content: center;
 } */


 .sunriseUl {
  display: table;
  margin: 0 auto;
 }

 .sunriseUl li{
  text-align: left;
 }

 .MuiToggleButton-root {
   text-transform: unset !important;
   /* margin: 10px !important; */
   color: rgb(243, 243, 243) !important;
   color: #4d4d4d !important;
 }

 /* .slide .css-ueukts-MuiButtonBase-root-MuiToggleButton-root.Mui-selected.focus {
  font-size: 16pt;
  padding: 20px;
  margin: 0px 15px 40px;
} */

 .Mui-selected:hover, .MuiButtonBase-root.MuiToggleButton-root.Mui-selected, .css-ueukts-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
  background-color: rgb(25 178 210 / 80%) !important;
  color: rgb(24 24 24 / 80%) !important;
  display: inline-flex;
}

.navigation {
  justify-content: center;
}

.static-card {
  text-align: center;
  display: block;
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 800px !important;
  /* width: 800px !important; */
}

.static-card .MuiTypography-h5 {
  /* font-size: 35pt; */
}

.static-card .MuiCardContent-root > p {
  /* font-size: 25pt; */
}

.static-card li {
  text-align: left;
}

.navigation-absolute {
  position: absolute;
  bottom: 50px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.headline-content {
  display: flex;
  flex-direction: column;
  /* height: 30%; */
  justify-content: center;
  padding: 1em 0px;
}

.slide-with-headline-content {
  /* height: 70%;
  justify-content: flex-start !important; */
}

.chip-subtext {
  font-size: 16pt;
}

.example-text {
  font-style: italic;
}

.quote-block {
  font-size: 16pt;
  background-color: #9bd3d5;
  padding: 10px;
  align-items: center;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
}

.sub-caption {
  font-size: 10pt;
}

.whiteDropdown legend, .whiteDropdown span, .whiteDropdown svg{
  color: white;
}

.whiteDropdown fieldset {
  border-color: white;
}

.MuiInputLabel-root {
  color: white;
}

.vertically-center {
  display: flex;
  align-items: center;
  justify-content: center;
}



.slide.fp-slide > div {
  margin: 0 3em;
}

.styled-text-field {
  background: transparent;
  /* color: white;
  border-color: white; */
  max-width: 90%;
  /* width: 750px; */
  box-sizing: border-box;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  font-size: 20px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  align-self: center;
  /* margin: 2em; */
  padding: 10px;
  opacity: 85%;
  min-width: 50%;
}

.styled-text-field:focus {
  outline: none !important;
}

.black-text-field {
  color: rgb(24 24 24);
  border-color: rgb(24 24 24);
}

.cards {
  max-width: 750px;
  padding: 0px;
}

.card-body{
  max-width: 100%;
}

.bmi-dropdown {
  display: flex;
  flex-direction: row;
  padding: 30px 0px;
  align-items: center;
  justify-content: space-between;
}


.vertical-distribute > .fp-slides {
  height: auto !important;
}

/* Fixes image in ImgLayout exceeding the height of the parent */
.img-grid {
  /* min-height: 0; */
  resize: both;
  height: 100%;
}

.img-grid a{
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.img-grid img {
  object-fit: contain;
  height: 85%;
  width: 100%;
}

.MuiChip-filled.MuiChip-sizeMedium.chip-small {
  padding: 0px 15px;
}

.spacer {
  padding: 50px;
}

#YourTurn .fp-slides {
  height: 80% !important;
}

#Closing .vertical-distribute {
  align-items: flex-start;
}

/* #ValuesAffirmation  */
.slide-inner {
  justify-content: flex-start;
  display: flex;
  height: 100%;
  flex-direction: column;
}

/* > div:first-of-type */
.slide-inner {
  /* height: calc(100% - 100px); */
  display: flex;
  /* justify-content: center; */
  flex-direction: column;

  /* vertical distribute */
  align-items: center;
  flex-direction: column !important;
  justify-content: space-evenly;
}

.height-remaining-nav {
  height: calc(100% - 100px);
  display: flex;
}

.slide-inner .MuiPaper-root {
  overflow: visible;
}

.MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root {
  white-space: unset !important;
}

#MISummary .vertical-distribute > .fp-slides {
  height: 100% !important;
}











@media only screen and (max-width: 1080px) {
  .bmi-dropdown {
    flex-direction: column;
  }

  body #root .slide .page-container .card-container {
    width: 70%;
  }
}



/*  Mobile */
@media only screen and (max-width: 800px) {
  .MuiGrid-root.img-grid {
    display: none;
  }

  .MuiGrid-root.content-grid {
    flex-basis: 100%;
    max-width: 100%;
  }

  .spacer {
    padding: 30px;
  }

  .spacer-small {
    padding: 17px;
  }

  .static-card {
    max-width: inherit !important;
    width: inherit !important;
  }

  .navigation-absolute {
    bottom: 20px;
  }

  .height-remaining-nav {
    height: calc(100% - 75px);
  }

  body #root .slide .page-container .card-container {
    width: 80%;
  }
  
}

@media only screen and (max-width: 650px) {
  .section-inner {
    padding: 0 5px;
  }

  .bmi-dropdown {
    padding: 0px;
  }

  .spacer {
    padding: 20px;
  }

  .spacer-small {
    padding: 7px;
  }

  .navigation-absolute {
    bottom: 15px;
  }

  .height-remaining-nav {
    height: calc(100% - 50px);
  }


  .pageTitle {
    padding-bottom: .5em;
  }

  
  #HowMuchAgree .MuiFormControl-root {
    padding-top: 15px !important;
  }

  #HowMuchAgree .MuiRadio-root.MuiRadio-colorPrimary.MuiButtonBase-root {
    padding: 4px;
  }

  #HowMuchAgree label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd {
    margin: 2px !important;
  }

  #HowMuchAgree label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd > span{
    font-size: 11pt;
  }

  .navigation-absolute button {
    font-size: 8pt;
    height: 28px;
  }

  .navigation-absolute svg {
    width: 0.7em;
  }

  body #root .slide .page-container .card-container {
    width: 100%;
  }

  .slide.fp-slide > div {
    margin: 10px;
  }
}

@media only screen and ((max-width: 550px) or (max-height: 600px)) {
  .section-inner {
    padding: 0em;
  }

  .spacer {
    display: none;
  }

  .spacer-small {
    padding: 3px;
    display: none;
  }

  .navigation-absolute {
    bottom: 10px;
  }

  .pageTitle {
    padding-bottom: .3em;
  }
  
}


@media only screen and ((max-width: 450px) or (max-height: 500px)) {

  .section-inner h1 {
    /* font-size: 2em; */
    padding: 0px;
    margin: 0px 0px 10px;
  }
  
  .pageTitle {
    padding-bottom: 0;
  }

  .slide.fp-slide > div, .section-inner {
    margin: 0px;
    padding: 10px;
  }

  .slide.fp-slide > div {
    margin: 0px;
  }

  .slide .MuiToggleButton-root {
    margin: 5px;
    padding: 3px;
  }

  #AboutYouButtons button{
    font-size: 1rem;
    padding: 3px;
    margin: 7px !important;
  }

  #ValuesAffirmation .css-ueukts-MuiButtonBase-root-MuiToggleButton-root.Mui-selected.focus {
    margin: 3px !important;
    padding: 3px;
    font-size: 1.2rem;
  }

  .slide .MuiFormControlLabel-root {
    margin: 0px;
  }
  .css-vqmohf-MuiButtonBase-root-MuiRadio-root {
    padding: 3px;
  }

  .styled-text-field {
    width: 90%;
  }

  .MuiChip-label.MuiChip-labelMedium {
    padding: 0px;
  }

  .navigation-absolute {
    bottom: 10px;
  }
}

/* Background colors */
#Welcome, #Closing, #ThankYou, #Feedback {
	background-color: rgb(238, 234, 242);
}

#ValuesAffirmation  {
	background-color: rgb(249, 199, 161);
}

#EnhancedHealthIntro, #EnhancedHealth, #EnhancedHealthSlides {
	background-color: rgb(239, 180, 186);
}

#FirstYear, #PersonalRiskFactors, #BMI {
	background-color: rgb(220, 230, 242);
}

#WhatReasonsIntro, #WhatReasons, #Quotes, #KnowledgeIsPower, #MISummary {
	background-color: rgb(202, 222, 222);
}

#MakeAPlan, #YourTurn, #NiceWork {
	background-color: rgb(255, 221, 167);
}

/* #Closing, #Feedback {
	background-color: rgb(247, 242, 255);
} */
