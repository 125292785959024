:root { --time: 0.707s; }

.cards {
  display: grid;
}

.card {
  perspective: 40rem;
  color: #242424;
  cursor: default;
}

.card-body {
  display: flex;
  transform-style: preserve-3d;
  transition: var(--time) transform;

  // .card:hover &, .card:focus & {
  .flipped {
    transform: rotateY(-180deg);
  }
}

.card-body.flipped {
    transform: rotateY(-180deg);
  }


.card-front, .card-back {
  backface-visibility: hidden;
  min-width: 100%;
}

.card-back {
  transform: rotateY(-180deg) translate(100%, 0%);
}

/* Make it Pretty */

body {
  line-height: 1.3;
  font-family: sans-serif;
}

.cards {
  grid-gap: 1.5rem;
  grid-auto-rows: 1fr;
  // padding: 3rem;
  // background: whitesmoke;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
}

.card {
  display: flex;
  transition: z-index, transform calc(var(--time) / 4);
  transition-delay: var(--time), 0s;
  text-decoration: none;
  z-index: 0;
  
  // &:hover {
  .flipped {
    transition-delay: 0s;
    z-index: 1;
  }

  // &:active {
  //   transform: scale(0.975);
  // }
}

.card-body {
  border-radius: 0.5rem;
  flex: 1;
}

.card-front, .card-back {
  @extend .shadow;
  display: flex;
  align-items: center;
  // background-color: white;
  background-color: rgb(255 204 196);
  box-sizing: border-box;
  padding: 1.5rem;
  border-radius: 0.5rem;
}

.card-front {
  font-size: 1.5rem;
}

.shadow {
  box-shadow: 0 -1px 1px rgba(0,0,0,0.04), 
              0 2px 2px rgba(0,0,0,0.04), 
              0 4px 4px rgba(0,0,0,0.04), 
              0 8px 8px rgba(0,0,0,0.04),
              0 16px 16px rgba(0,0,0,0.04); 
}
