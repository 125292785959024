$main-font: 'Yanone Kaffeesatz',
sans-serif;
//$new-white: rgb(248, 246, 234);
$new-white: rgb(255 204 196);
//$new-white: rgb(255, 255, 255);
$text-margin: 10px;
$breakpoint-phone-medium: 450px;
$breakpoint-phone-small: 350px;

body {
    height: 100%;
    width: 100%;
    margin: 0;
    background: rgb(209, 182, 214);
    font-family: $main-font;
    position: absolute;
    box-sizing: border-box;
    #root {
        height: 100%;
        .page-container {
            position: relative;
            width: 100%;
            height: 100%;
            // display: block;
            perspective: 1000px;

            display: flex;
            align-items: center;
            // justify-content: center;
            flex-direction: column;

            // .card-page-title {
            //     font-size: 45pt;
            //     padding-bottom: 50px;
            //     @media (max-width: $breakpoint-phone-medium) {
            //         font-size: 25pt;
            //         padding-bottom: 15px;
            //     }
            //     @media (max-width: $breakpoint-phone-small) {
            //         font-size: 18pt;
            //         padding-bottom: 8px;
            //     }
            // }

            .card-container {
                color: black;
  
                // right: 50%;
                // top: 50%;
                // position: absolute;
                // transform: translate(50%,-50%);
                
                color: black;
                height: 250px;
                padding: 0;
                margin: 0px 0px 50px;
                // top: 20px;
                width: 50%;
                @media (max-width: $breakpoint-phone-medium) {
                    width: 90%;
                }
            //     margin-left: 35%;
            //   margin-right: 35%;
                // position: relative;
                transform-style: preserve-3d;
                box-sizing: border-box;
                &.flipped {
                    .front {
                        transform: rotateY(180deg);
                    }
                    .back {
                        transform: rotateY(0deg);
                    }
                }
                .front,
                .back {
                    box-sizing: border-box;
                    height: 100%;
                    width: 100%;
                    display: block;
                    background: $new-white;
                    box-shadow: 10px 10px 5px rgb(95, 77, 99);
                    padding: 10px;
                    position: absolute;
                    border-radius: 10px;
                    backface-visibility: hidden;
                    transform-style: preserve-3d;
                    transition: -webkit-transform ease 500ms;
                    transition: transform ease 500ms;

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    align-content: center;
                    
                }
                .front {
                    z-index: 2;
                    transform: rotateY(0deg);
                    
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    
                    // height: 75%;
                    /* front tile styles go here! */
                }
                .back {
                    background: $new-white;
                    transform: rotateY(-180deg);
                    padding: 20px;
                    font-size: 20px;
                    /* back tile styles go here! */

                    h1.underline {
                        text-decoration: underline;
                        @media (max-width: $breakpoint-phone-medium) {
                            margin-top: 0px;
                            font-size: 25px;
                        }
                    }
                }
                .image-container {
                    position: relative;
                    .card-image {
                        width: 100%;
                        border-radius: 10px;
                    }
                    .title {
                        font-size: 34px;
                        position: absolute;
                        margin-bottom: $text-margin;
                        margin-left: $text-margin;
                        bottom: 0;
                        left: 0;
                        color: $new-white;
                    }
                }
                .main-area {
                    margin-left: $text-margin;
                    margin-top: $text-margin;
                    margin-right: $text-margin;
                    
                    .blog-post {
                        height: 100%;
                        
                        .date {
                        margin-top: $text-margin;
                        margin-bottom: 5px;
                        font-size: 20px;
                    }
                    .read-more {
                        font-size: 20px;
                        position: relative;
                        bottom: 0px;
                        text-align: right;
                    }
                        .blog-content {
                            height: 90%;
                            display: block;
                            margin-top: $text-margin;
                            margin-bottom: $text-margin;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
        footer {
            position: fixed;
            bottom: 0;
            right: 0;
            margin: 20px;

        }
    }
}

